<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6">
        <button class="btn btn-yup-purple" v-modal="{ target: 'url-modal' }">
          {{$t('anti-fraud-component.register-url')}}
        </button>
      </div>
      <div class="col-12 col-md-6">
        <form @submit.prevent="fetch(1)">
          <div class="form-group">
            <div class="input-group mb-3">
              <input
                v-model="form.search"
                type="text"
                :placeholder="`${$t('generic-str.search-by.default')}...`"
                aria-label=""
                aria-describedby="basic-addon2"
                class="form-control"
              />
              <div class="input-group-append">
                <button
                  type="submit"
                  class="btn btn-yup-purple btn-icon btn-search-id"
                >
                  <i class="fas fa-search text-white"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="fetched && urls.length" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>{{$t('generic-str.date')}}</th>
                <th>{{$tc('generic-str.word', 1)}}</th>
                <th>Status</th>
                <th>{{$t('generic-str.customer')}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="url in urls" :key="url.id">
                <td>{{ url.created_at | date('DD/MM/YYYY HH:mm:ss') }}</td>
                <td class="max-200">{{ url.url }}</td>
                <td>
                  <span
                    v-if="url.status == 'whitelisted'"
                    class="badge badge-success"
                    >{{$t('generic-str.status.lbl-released')}}</span
                  >
                  <span
                    v-else-if="url.status == 'blacklisted'"
                    class="badge badge-danger"
                    >{{$t('generic-str.status.lbl-blocked-o')}}</span
                  >
                </td>
                <td>
                  <span v-if="url.account">{{ url.account.name }}</span>
                  <span v-else>{{$t('generic-str.all')}}</span>
                </td>
                <td>
                  <button
                    @click="destroy(url.id)"
                    class="btn btn-yup-purple-outline btn-remove"
                  >
                    <i class="icon dripicons-trash text-yup"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="fetched" class="text-center table-placeholder">
          <!-- <i class="fas fa-envelope font-size-80"></i> -->
          <LottieAnimNoData />
          <h5 class="card-title m-t-20">{{$t('warnings.anti-fraud.url')}}</h5>
        </div>
        <!-- <div v-else class="qt-block-ui relative" style="padding: 120px" /> -->
        <div class="loading min-h-300" v-else>
          <LoadingAnim />
        </div>
        <br />
        <pagination :lastPage="pages" @change="fetch" />
      </div>
    </div>
    <url-modal @sent="validated" id="url-modal"></url-modal>
  </div>
</template>

<script>
import AntifraudService from '@/services/admin/antifraud.service';
import Pagination from '@/components/Pagination.vue';
import UrlModal from '@/components/rebranding/antifraud/UrlModal.vue';
import Swal from 'sweetalert2';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'AntifraudUrls',
  components: {
    Pagination,
    UrlModal,
    LottieAnimNoData,
    LoadingAnim,
  },
  data() {
    return {
      fetched: false,
      urls: [],
      pages: 1,
      form: {
        search: '',
        page: 1,
      },
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      AntifraudService.getUrls(this.form).then(
        (response) => {
          this.fetched = true;
          this.urls = response.data;
          this.pages = response.last_page;
          this.totalMessages = response.total;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    validated() {
      this.fetch(1);
      this.$emit('validated');
    },
    destroy(urlId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('warnings.anti-fraud.label'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          AntifraudService.deleteUrl(urlId).then(
            () => {
              this.fetch(1);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, `${this.$t('warnings.anti-fraud.deleted')}.`, 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>
